import React from 'react';
import { useParams } from 'react-router-dom';
import { roles, routes } from '../../../../../constants';
import { alertOptions } from '../../../../../constants/amr-pipeline/alertOptions';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { StatisticsAlertOption } from '../../../../../types/amr-pipeline/enums/AlertOption';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { arrayUtils, isRequesting } from '../../../../../utils';
import { TabItem } from '../../../../bidding/common/tab-list';
import { EmailPreferencesTab } from '../EmailPreferencesContent';
import { user } from '../../../../../user';
import { usePageConfig } from '../../../../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../../../../types/page-config/PageConfigType';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { emailPreferencesUtils } from '../../../../../utils/email-preferences.utils';
import { PortfolioUserConfigFilter, UserConfigFilter } from '../../../../../types/user-config/UserConfigFilter';
import { hasBankAnalyticsAlertsAccess, hasBankAnalyticsAlertsChangeAccess } from '../../../../../utils/amr-pipeline.utils';

interface Props {
    tabPath: EmailPreferencesTab,
    title: React.ReactNode;
    disabled?: boolean;
}

export function EmailPreferencesTabItem({ tabPath, title, disabled }: Props) {
    const { tab } = useParams<{ tab: string }>();

    const isSellerCollateralManager = user.hasRoles(roles.CollateralManager);
    const withImApAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const withManagerProfileAccess = user.hasFeatures(SubscriptionFeature.ManagerProfileManagerRelatedAlerts);
    const withBwicAccess = user.hasFeatures(SubscriptionFeature.BwicMonitorAlerts);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withDealerProfileAccess = user.hasFeatures(SubscriptionFeature.DealerProfileEmailAlerts);
    const portfolioImAlertsAccess = user.hasAllFeatures(...[
        SubscriptionFeature.IssuanceMonitorFullAccess,
        SubscriptionFeature.PortfolioAlerts,
    ]);

    const userCompany = useAppSelector(s => s.issuanceMonitor.amrPipelineCommon.userCompany);
    const banks = useAppSelector(s => s.manageEmailPreferences.banks);
    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const editPreferences = useAppSelector(s => s.manageEmailPreferences.editPreferences);
    const editIssuanceMonitorPreferences = useAppSelector(s => s.manageEmailPreferences.editIssuanceMonitorPreferences);
    const editCloManagersPreferences = useAppSelector(s => s.manageEmailPreferences.editCloManagersPreferences);
    const editSavedFiltersPreferences = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);
    const editBanksPreferences = useAppSelector(s => s.manageEmailPreferences.editBanksPreferences);
    const editArrangerPipelinePreferences = useAppSelector(s => s.manageEmailPreferences.editArrangerPipelinePreferences);
    const portfolioSavedFilterAlerts = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);
    const { config: bwicMonitorPageConfig } = usePageConfig(PageConfigType.BwicMonitorAll, true);
    const { config: portfolioPageConfig } = usePageConfig(PageConfigType.Portfolio, true);
    const { config: dealerInventoryPageConfig } = usePageConfig(PageConfigType.DealerInventory, true);

    const withAnalyticsAccess = hasBankAnalyticsAlertsAccess(banks, userCompany);
    const withAnalyticsChangeAccess = hasBankAnalyticsAlertsChangeAccess(banks, userCompany);

    const getDefaultGlobalAlertStatus = (alerts: Partial<{ alertOption: AlertOption }>[] = []) => {
        let status = null;
        for (let option of alertOptions) {
            if (alerts.every((f) => f.alertOption === option)) {
                status = option;
                break;
            }
        }
        return status;
    };

    const getCloManagerCounters = () => {
        const cloManagerAnalyticsValue = editCloManagersPreferences?.cloManagerAnalyticsAlert.value.find(
            a => a.companyReferenceName === userCompany?.referenceName,
        );


        const cloManagerAnalyticSectionCounter = Number(
            Boolean(
                isSellerCollateralManager &&
                cloManagerAnalyticsValue &&
                cloManagerAnalyticsValue.alertOption !== AlertOption.Never,
            )
        );

        const cloManagerBwicSectionCounter = Number(
            Boolean(editCloManagersPreferences?.cloManagerBwicAlert.value) &&
            getDefaultGlobalAlertStatus(
                editCloManagersPreferences?.cloManagerBwicAlert!.value,
            ) !== AlertOption.Never,
        );

        const cloManagerDiSectionCounter = Number(
            Boolean(editCloManagersPreferences?.cloManagerDealerInventoryAlert.value) &&
            getDefaultGlobalAlertStatus(
                editCloManagersPreferences?.cloManagerDealerInventoryAlert!.value,
            ) !== AlertOption.Never,
        );

        const cloManagerImSectionCounter = Number(
            Boolean(editCloManagersPreferences?.cloManagerIssuanceMonitorAlert.value) &&
            getDefaultGlobalAlertStatus(
                editCloManagersPreferences?.cloManagerIssuanceMonitorAlert!.value,
            ) !== AlertOption.Never,
        );

        const cloManagerApSectionCounter = Number(
            Boolean(editCloManagersPreferences?.cloManagerArrangerPipelineAlert.value) &&
            getDefaultGlobalAlertStatus(
                editCloManagersPreferences?.cloManagerArrangerPipelineAlert!.value,
            ) !== AlertOption.Never,
        );

        return [
            ...(isSellerCollateralManager ? [withManagerProfileAccess ? cloManagerAnalyticSectionCounter : 0] : []),
            ...(withManagerProfileAccess && withImApAccess ? [cloManagerImSectionCounter, cloManagerApSectionCounter] : [0, 0]),
            (withBwicAccess ? cloManagerBwicSectionCounter : 0),
            (withDiAccess ? cloManagerDiSectionCounter : 0),
        ];
    }

    const getBanksCounters = () => {
        const bankDiSectionCounter = Number(
            Boolean(editBanksPreferences?.bankDealerInventoryAlert.value) &&
            getDefaultGlobalAlertStatus(
                editBanksPreferences?.bankDealerInventoryAlert!.value,
            ) !== AlertOption.Never,
        );

        const bankImSectionCounter = Number(
            Boolean(editBanksPreferences?.bankIssuanceMonitorAlert.value) &&
            getDefaultGlobalAlertStatus(
                editBanksPreferences?.bankIssuanceMonitorAlert!.value,
            ) !== AlertOption.Never,
        );


        const bankApSectionCounter = Number(
            Boolean(editBanksPreferences?.bankArrangerPipelineAlert.value) &&
            getDefaultGlobalAlertStatus(
                editBanksPreferences?.bankArrangerPipelineAlert!.value,
            ) !== AlertOption.Never,
        );

        const bankCounter = [
            withDiAccess ? bankDiSectionCounter : 0,
            ...(withDealerProfileAccess && withImApAccess ? [bankImSectionCounter, bankApSectionCounter] : [0, 0]),
        ];

        if (withAnalyticsAccess) {
            const bankAnalyticsCounter = withAnalyticsChangeAccess
                ? Number(
                      Boolean(editBanksPreferences?.bankAnalyticsAlert.value?.length) &&
                          editBanksPreferences?.bankAnalyticsAlert.value.find(
                              a => a.companyReferenceName === userCompany?.referenceName,
                          )?.alertOption !== AlertOption.Never,
                  )
                : 0;

            bankCounter.unshift(bankAnalyticsCounter);
        }

        return bankCounter;
    }

    const getBwicMonitorCounters = () => {
        const isSavedFiltersAlertOptionActive =
            bwicMonitorPageConfig?.filters.some(f => {
                const original = f.alertOption;
                const changed = editSavedFiltersPreferences[f.referenceName];
                const current = changed ?? original;

                return current !== AlertOption.Never
            });

        const canUseBwicMonitorAlerts = user.hasFeatures(SubscriptionFeature.BwicMonitorAlerts);

        return [
            canUseBwicMonitorAlerts ? Number(editPreferences.bwicByRatingAlertState !== AlertOption.Never) : 0,
            canUseBwicMonitorAlerts ? Number(isSavedFiltersAlertOptionActive) : 0,
            canUseBwicMonitorAlerts ? Number(editPreferences.secondaryWeeklySummaryAlertState === AlertOption.Weekly) : 0
        ];
    }

    const getInventoryCounters = () => {
        const isSavedFiltersAlertOptionActive =
            dealerInventoryPageConfig?.filters.some(f => {
                const original = f.alertOption;
                const changed = editSavedFiltersPreferences[f.referenceName];
                const current = changed ?? original;

                return current !== AlertOption.Never
            });

        const canUseInventoryAlerts = user.hasFeatures(SubscriptionFeature.InventoryAlerts);

        return [
            canUseInventoryAlerts ? Number(editPreferences.dealerInventoryAlertState !== AlertOption.Never) : 0,
            canUseInventoryAlerts ? Number(isSavedFiltersAlertOptionActive) : 0
        ];
    }

    const getPortfolioCounters = () => {
        const getActualFilter = (filter: UserConfigFilter) => {
            const original = (filter as PortfolioUserConfigFilter);
            const changed = portfolioSavedFilterAlerts[filter.referenceName];
            return changed ?? { bwicAlert: original.bwicAlertOption, inventoryAlert: original.dealerInventoryAlertOption };
        }

        const isSavedFiltersBwicAlertOptionActive = portfolioPageConfig?.filters.some(f =>
            getActualFilter(f).bwicAlert === AlertOption.Instant
        );
        const isSavedFiltersInventoryAlertOptionActive = portfolioPageConfig?.filters.some(f =>
            getActualFilter(f).inventoryAlert === AlertOption.Instant
        );

        return [
            Number(emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioBwicAlertState) !== AlertOption.Never),
            Number(emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioDealersInventoryAlertState) !== AlertOption.Never),
            Number(portfolioImAlertsAccess && emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioCleansingNoticeAlertState) !== AlertOption.Never),
            Number(portfolioImAlertsAccess && emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioIssuanceMonitorAlertState) !== AlertOption.Never),
            Number(portfolioImAlertsAccess && emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioOutOfRiPeriodAlertState) !== AlertOption.Never),
            Number(portfolioImAlertsAccess && emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioOutOfNcPeriodAlertState) !== AlertOption.Never),
            Number(portfolioImAlertsAccess && emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.portfolioRollerDeadlineAlertState) !== AlertOption.Never),
            user.hasFeatures(SubscriptionFeature.PortfolioAlerts) ? Number(isSavedFiltersBwicAlertOptionActive) : 0,
            user.hasFeatures(SubscriptionFeature.PortfolioAlerts) ? Number(isSavedFiltersInventoryAlertOptionActive) : 0
        ];
    }

    const getCounters = () => {
        switch (tabPath) {
            case EmailPreferencesTab.Dashboard:
                return [user.hasFeatures(SubscriptionFeature.CanUseDashboardAlerts)
                    ? Number(editPreferences.dailyDashboardEmail !== AlertOption.Never)
                    : 0
                ];
            case EmailPreferencesTab.BwicMonitor:
                return getBwicMonitorCounters();
            case EmailPreferencesTab.MyBwics:
                return [
                    Number(emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.newBwicAlertState) !== AlertOption.Never),
                    Number(emailPreferencesUtils.convertAlertEnabledStateToAlertOption(editPreferences.bwicReminderAlertState) !== AlertOption.Never)
                ]
            case EmailPreferencesTab.IssuanceMonitor:
                const alertsCount = [
                    Number(Boolean(editIssuanceMonitorPreferences?.imAlertConfig.value.alertOption) && editIssuanceMonitorPreferences?.imAlertConfig.value.alertOption !== AlertOption.Never),
                    Number(Boolean(editIssuanceMonitorPreferences?.newTransactionAlertConfig.value.alertOption) && editIssuanceMonitorPreferences?.newTransactionAlertConfig.value.alertOption !== AlertOption.Never),
                    Number(Boolean(editIssuanceMonitorPreferences?.weeklyStatsAlertConfig.value.emailOption) && editIssuanceMonitorPreferences?.weeklyStatsAlertConfig.value.emailOption !== StatisticsAlertOption.Never),
                    Number(Boolean(editIssuanceMonitorPreferences?.filtersConfig.ImFilter.value) && getDefaultGlobalAlertStatus(editIssuanceMonitorPreferences?.filtersConfig.ImFilter.value) !== AlertOption.Never)
                ];

                return withImApAccess ? alertsCount : alertsCount.map(i => 0);
            case EmailPreferencesTab.ArrangerPipeline:
                return [
                    withImApAccess
                        ? Number(Boolean(
                            editArrangerPipelinePreferences?.apAlertConfig.value.alertOption !== AlertOption.Never
                        ))
                        : 0
                ];
            case EmailPreferencesTab.Portfolio: return getPortfolioCounters();
            case EmailPreferencesTab.DealerInventory: return getInventoryCounters();
            case EmailPreferencesTab.CloManagers:
                return getCloManagerCounters();
            case EmailPreferencesTab.Banks:
                return getBanksCounters();
            default:
                return [];
        }
    }

    const counters = getCounters();
    const count = arrayUtils.sum(counters, (item) => item);

    return (
        <TabItem
            active={tabPath === tab}
            title={<>{title} <span className="text-regular text-warm-grey">({count}/{counters.length})</span></>}
            path={routes.profileNotificationsTab(tabPath)}
            disabled={isRequesting(requestStateGetPreferences) || disabled}
        />
    )
}
