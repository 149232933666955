import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { isRequesting } from "../../../../../../utils";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";

export function BwicMonitorSecondaryWeeklySummary() {
    const dispatch = useAppDispatch();
    const alertOption = useAppSelector(s => s.manageEmailPreferences.editPreferences.secondaryWeeklySummaryAlertState);
    const isRequestingSavePreferences = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));

    return (
        <EmailPreferencesRadioGroup
            value={alertOption}
            alertOptions={[AlertOption.Never, AlertOption.Weekly]}
            alertOptionInfo={{
                [AlertOption.Weekly]:
                    "Alerts are sent at 3 PM EST every Friday"
            }}
            groupNameIndentifier="secondary-weekly-summary-alert-state"
            title="Secondary Weekly Summary"
            description="Receive an email notification with CLO secondary market highlights"
            disabled={isRequestingSavePreferences}
            onChange={selectedOption => {
                const option = selectedOption === AlertOption.Weekly ? AlertOption.Weekly : AlertOption.Never;
                dispatch(manageEmailPreferencesActions.setSecondaryWeeklySummaryAlert(option))
            }}
            requiredFeatures={[SubscriptionFeature.BwicMonitorAlerts]}
        />
    )
}